import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function TrainingsPage(props) {
  return (
    <Layout>
      <Seo title="Trainings" />
      <div class="contentBox trainingsBox">
        <h1>Staff Trainings</h1>
        <p style={{ textAlign: "center" }}>
          All staff must complete initial 32-hour orientation before being able
          to be left alone in the facility. Four hours of the 32-hour
          orientation can be job shadowing at the facility, which could count
          toward the initial 32-hours. Topics of training will include, but are
          not limited to:
        </p>
        <ul>
          <li>Person-Centered Planning</li>
          <li>Mandated Abuse Reporting Requirements, Title 22</li>
          <li>
            Disaster Response, Medical Emergency Response, Medicated
            Procedures/Universal Precautions
          </li>
          <li>Crisis Prevention Intervention (CPI)</li>
        </ul>
      </div>
      <div class="contentBox trainingsBox">
        <h1>Individual Trainings</h1>
        <p style={{ textAlign: "center" }}>
          The following training will be tailored to the individual needs of the
          client from significant self-help to severe deficits and impairments.
        </p>
        <ul>
          <li>
            <h3>Independent Living Skills</h3>
          </li>
          <ul>
            <li>
              <h4>Dressing Skills</h4>
            </li>
            <ul>
              <li>Choosing appropriate clothing.</li>
              <li>Proper methods of how to put on and take off clothing.</li>
            </ul>
            <li>
              <h4>Personal Hygiene </h4>
            </li>
            <ul>
              <li>Proper oral hygiene.</li>
              <li>Face washing.</li>
              <li>Proper brushing or grooming of one’s hair.</li>
              <li>
                Washing of hands after using the toilet and before eating.
              </li>
              <li>
                Teaching females proper use of sanitary napkins during menses,
                and appropriate disposal.
              </li>
              <li>
                Assistance in all other areas of personal hygiene will be given
                as needed.
              </li>
            </ul>
            <li>
              <h4>Bathing</h4>
            </li>
            <ul>
              <li>Proper use of tubs or showers.</li>
              <li>How to wash self with soap and rinse soap off.</li>
              <li>Proper drying after bathing.</li>
              <li>
                Assistance in all other areas of bathing will be given as
                needed.
              </li>
            </ul>
            <li>
              <h4>Bladder and Bowel Control</h4>
            </li>
            <ul>
              <li>
                Recognizing the need to empty bladder and bowel; and use of
                toilet.
              </li>
              <li>Proper use of toilet and the use of paper to clean self.</li>
              <li>Proper toilet flushing.</li>
              <li>Washing and drying hands after each use.</li>
            </ul>
            <li>
              <h4>Eating</h4>
            </li>
            <ul>
              <li>
                How to eat properly using utensils (Including adaptive
                equipment).
              </li>
              <li>Chewing before swallowing.</li>
              <li>Wiping mouth with napkins when needed.</li>
              <li>Drinking from a cup or glass.</li>
              <li>
                Appropriate table manners, and proper conduct at the table.
              </li>
            </ul>
            <li>
              <h4>Food Preparation</h4>
            </li>
            <ul>
              <li>
                Learn how to prepare simple food items such as fixing cereal,
                pouring milk, making and buttering toast.
              </li>
              <li>Clean up preparation area.</li>
            </ul>
            <li>
              <h4>Washing Dishes</h4>
            </li>
            <ul>
              <li>Coaching in bringing dirty dishes from table.</li>
              <li>Rinsing and putting into dishwasher properly.</li>
            </ul>
            <li>
              <h4>Bedroom Maintenance</h4>
            </li>
            <ul>
              <li>Learning how to fold clothes.</li>
              <li>Proper placement of clothes into drawers or closet.</li>
              <li>Techniques on how to put dirty clothes into hamper.</li>
              <li>Dusting of furniture.</li>
            </ul>
            <li>
              <h4>Bed Making</h4>
            </li>
            <ul>
              <li>
                Learn how to tuck in sheets, blankets, and smooth bed spread.
              </li>
              <li>Striping of the bed once a week, or when necessary.</li>
              <li>Reapplication of bed linens.</li>
            </ul>
          </ul>
          <li>
            <h3>Community Skills</h3>
          </li>
          <ul>
            <li>
              <h4>Making Purchases</h4>
            </li>
            <ul>
              <li>
                Coaching will be provided in making a choice as to what to buy,
                learning how to access the cashier, offering money and waiting
                for change.
              </li>
            </ul>
            <li>
              <h4>Ordering Food</h4>
            </li>
            <ul>
              <li>
                Making a choice as to what to eat, where to order, offering
                money and waiting for change.
              </li>
            </ul>
            <li>
              <h4>Attending Functions</h4>
            </li>
            <ul>
              <li>
                Choosing activities in which they would like to participate,
                paying the proper amount at the designated area, and waiting for
                change. Assistance will be given as needed.
              </li>
            </ul>
            <li>
              <h4>Social Skills</h4>
            </li>
            <ul>
              <li>
                Coach effective social interaction with peers and the public,
                develop friendships, participation in social activities,
                participating in group projects, and reducing unacceptable
                social behavior.
              </li>
            </ul>
            <li>
              <h4>Effective Emotional Response</h4>
            </li>
            <ul>
              <li>Diminishing aggressive behaviors.</li>
              <li>Diminishing self-injurious behaviors.</li>
              <li>Diminishing property destruction.</li>
              <li>Diminishing depressive behaviors.</li>
              <li>Redirecting behaviors resulting in running away.</li>
              <li>
                Reducing repetitive behaviors such as inappropriate undressing,
                and resistive behaviors.
              </li>
              <li>Positive reinforcement and redirection will be used.</li>
              <li>
                When necessary, other training will be developed with assistance
                from Regional Center, counselors, and specialists.
              </li>
            </ul>
            <li>
              <h4>Developing Cognitive Skills</h4>
            </li>
            <ul>
              <li>Use of hearing aids.</li>
              <li>Use of glasses & visual aids.</li>
              <li>Associating time with events.</li>
              <li>Teaching number awareness.</li>
              <li>Writing skills.</li>
              <li>Safety Awareness.</li>
            </ul>
            <li>
              <h4>Communication Skills</h4>
            </li>
            <ul>
              <li>Increasing vocabulary.</li>
              <li>
                Increasing clarity of speech and conversational skills through
                opportunities to have conversations with peers and others in the
                community.
              </li>
            </ul>
            <li>
              <h4>Health Maintenance</h4>
            </li>
            <ul>
              <li>
                Doctor and dental visits will be scheduled annually and as
                needed.
              </li>
              <li>
                Provisions of special diets as prescribed by the physician.
              </li>
              <li>Continuous health observations.</li>
              <li>Assistance with medications will be provided as needed.</li>
            </ul>
          </ul>
        </ul>
      </div>
    </Layout>
  )
}
